import store from "../../store";
import Errors from "../../Errors";
import CartHelpersMixin from "../../mixins/CartHelpersMixin";
import CartItemHelpersMixin from "../../mixins/CartItemHelpersMixin";

export default {
    mixins: [CartHelpersMixin, CartItemHelpersMixin],
    props: [
        "customerEmail",
        "customerPhone",
        "gateways",
        "defaultAddress",
        "addresses",
        "countries",
    ],

    data() {
        return {
            shippingMethodName: null,
            crossSellProducts: [],
            errors: new Errors(),
            sell_form: {
                customer_email: this.customerEmail,
                customer_phone: this.customerPhone,
                billing: {},
                newBillingAddress: false,
                billingAddressId: null,
            },
            states: {
                billing: {},
                shipping: {},
            },
        };
    },

    computed: {
        hasAddress() {
            return this.addresses !== undefined?Object.keys(this.addresses).length !== 0:false;
        },
        hasAnyCrossSellProduct() {
            return this.crossSellProducts.length !== 0;
        },
    },

    created() {
        if (!this.hasAddress) {
            this.sell_form.newBillingAddress = true;
        }
        this.$nextTick(() => {
            if (store.state.cart.shippingMethodName) {
                this.changeShippingMethod(store.state.cart.shippingMethodName);
            } else {
                this.updateShippingMethod(this.firstShippingMethod);
            }

            this.fetchCrossSellProducts();
        });
    },

    methods: {
        updateCart(cartItem, qty) {
            this.loadingOrderSummary = true;

            axios
                .put(route("cart.items.update", { id: cartItem.id }), {
                    qty: qty || 1,
                })
                .then((response) => {
                    store.updateCart(response.data);
                })
                .catch(({ response }) => {
                    store.updateCart(response.data.cart);

                    this.$notify(response.data.message);
                })
                .finally(() => {
                    this.loadingOrderSummary = false;
                });
        },

        updateSellCart(cartItem, qty) {
            this.loadingOrderSummary = true;

            axios
                .put(route("sell.items.update", { id: cartItem.id }), {
                    qty: qty || 1,
                })
                .then((response) => {
                    store.updateSellCart(response.data);
                })
                .catch(({ response }) => {
                    store.updateSellCart(response.data.cart);

                    this.$notify(response.data.message);
                })
                .finally(() => {
                    this.loadingOrderSummary = false;
                });
        },

        removeCartItem(cartItem) {
            this.loadingOrderSummary = true;

            store.removeCartItem(cartItem);

            if (store.cartIsEmpty()) {
                this.crossSellProducts = [];
            }

            axios
                .delete(route("cart.items.destroy", { id: cartItem.id }))
                .then((response) => {
                    store.updateCart(response.data);
                })
                .catch((error) => {
                    this.$notify(error.response.data.message);
                })
                .finally(() => {
                    this.loadingOrderSummary = false;
                });
        },

        clearCart() {
            store.clearCart();

            if (store.cartIsEmpty()) {
                this.crossSellProducts = [];
            }

            axios
                .delete(route("cart.clear"))
                .then((response) => {
                    store.updateCart(response.data);
                })
                .catch((error) => {
                    this.$notify(error.response.data.message);
                });
        },

        clearSellCart() {
            store.clearSellCart();

            if (store.sellIsEmpty()) {
                this.crossSellProducts = [];
            }

            axios
                .delete(route("sell.clear"))
                .then((response) => {
                    store.updateSellCart(response.data);
                })
                .catch((error) => {
                    this.$notify(error.response.data.message);
                });
        },

        changeShippingMethod(shippingMethodName) {
            this.shippingMethodName = shippingMethodName;
        },

        async fetchCrossSellProducts() {
            try {
                const response = await axios.get(
                    route("cart.cross_sell_products.index")
                );

                this.crossSellProducts = response.data;
            } catch (error) {
                this.$notify(error.response.data.message);
            }
        },
        onChangePaymentCategory(type){
            let subTotal = 0;
            $.each(store.state.sell.items, function(index, val){
                if(val.cashPrice.amount !== ''){
                    if(type == 'cash'){
                        subTotal += parseFloat(val.cashPrice.amount*val.qty);
                    }else{
                        subTotal += parseFloat(val.creditPrice.amount*val.qty);
                    }
                }
            });
            subTotal = parseFloat(subTotal).toFixed(2);
            store.state.sell.payment_category = type;

            store.state.sell.subTotal = subTotal;
            store.state.sell.total = subTotal;
        },
        processSellModal(){
            $("#processSellModal").modal('show');
        },
        onCloseModal(){
            $("#processSellModal").modal('hide');
        },
        changeBillingCity(city) {
            this.$set(this.sell_form.billing, "city", city);
        },
        changeBillingCountry(country) {
            this.$set(this.sell_form.billing, "country", country);

            if (country === "") {
                this.sell_form.billing.state = "";
                this.states.billing = {};

                return;
            }

            this.fetchStates(country, (response) => {
                this.$set(this.states, "billing", response.data);
                this.$set(this.sell_form.billing, "state", "");
            });
        },
        changeBillingZip(zip) {
            this.$set(this.sell_form.billing, "zip", zip);
        },
        fetchStates(country, callback) {
            axios
                .get(route("countries.states.index", { code: country }))
                .then(callback);
        },
        onSellConfirm(){
            axios.post(
                    route("sell.order.store"),
                    {
                        ...this.sell_form,
                        ...store.state.sell
                    }
                )
                .then((response) => {
                    console.log(response.data)
                    if(response.data.type == 'success'){
                        this.clearSellCart();
                        window.location.href = route("account.sells.index");
                    }
                    this.$notify(response.data.message);
                })
                .catch((error) => {
                    console.log('error');
                    /*this.placingOrder = false;
                    this.loadingOrderSummary = false;

                    this.deleteOrder(orderId);*/
                    this.$notify(error.response.data.message);
                });
        }
    },
};
