<template>
    <div class="product-card">
        <div class="product-card-top">

            <a v-if="category == 'sell' && product.variant != null" :href="productUrl+'&ref=sell'" class="product-image">
                <img :src="baseImage"
                    :class="{ 'image-placeholder': !hasBaseImage }"
                    :alt="product.name"
                />
            </a>

            <a v-else-if="category == 'sell' && product.variant == null" :href="productUrl+'?ref=sell'" class="product-image">
                <img :src="baseImage"
                    :class="{ 'image-placeholder': !hasBaseImage }"
                    :alt="product.name"
                />
            </a>

            <a v-else :href="productUrl" class="product-image">
                <img :src="baseImage"
                    :class="{ 'image-placeholder': !hasBaseImage }"
                    :alt="product.name"
                />
            </a>

            <div class="product-card-actions" v-if="typeof category == 'undefined' || category == 'shop'">
                <button
                    class="btn btn-wishlist"
                    :class="{ added: inWishlist }"
                    :title="$trans('storefront::product_card.wishlist')"
                    @click="syncWishlist"
                >
                    <i class="la-heart" :class="inWishlist ? 'las' : 'lar'"></i>
                </button>

                <button
                    class="btn btn-compare"
                    :class="{ added: inCompareList }"
                    :title="$trans('storefront::product_card.compare')"
                    @click="syncCompareList"
                >
                    <i class="las la-random"></i>
                </button>
            </div>

            <ul class="list-inline product-badge" v-if="typeof category == 'undefined' || category == 'shop'">
                <li class="badge badge-danger" v-if="item.is_out_of_stock">
                    {{ $trans("storefront::product_card.out_of_stock") }}
                </li>

                <li class="badge badge-primary" v-else-if="product.is_new">
                    {{ $trans("storefront::product_card.new") }}
                </li>
                <li class="badge " v-for="vari in product.variants" v-if="vari.name == 'New' || vari.name == 'Pre-Owned'" :class="vari.name == 'New'?'badge-success':'badge-primary'">
                   <span v-if="vari.is_in_stock"> {{ vari.name }}</span>
                </li>

                <li
                    class="badge badge-success"
                    v-if="item.has_percentage_special_price"
                >
                    -{{ item.special_price_percent }}%
                </li>
            </ul>
        </div>

        <div class="product-card-middle">
            <product-rating v-if="typeof category == 'undefined' || category == 'shop'"
                :ratingPercent="product.rating_percent"
                :reviewCount="product.reviews.length"
            >
            </product-rating>

            <a v-if="category == 'sell' && product.variant != null" :href="productUrl+'&ref=sell'" class="product-name">
                <h6>{{ product.name }}</h6>
            </a>
            <a v-else-if="category == 'sell' && product.variant == null" :href="productUrl+'?ref=sell'" class="product-name">
                <h6>{{ product.name }}</h6>
            </a>
            <a v-else :href="productUrl" class="product-name">
                <h6>{{ product.name }}</h6>
            </a>

            <!-- <div v-if="category == 'sell'" class="product-price product-price-clone fz-13" v-html="item.sell_price"></div> -->
            <div v-if="category == 'sell'" class="product-price product-price-clone fz-12" >
                <span class='text-left'>Cash: {{ parseFloat(product.cash_price.amount).toFixed(2) }}</span><strong class='extra-bar-price'> &nbsp; | &nbsp; </strong><span class='text-right'>Credit: {{ parseFloat(product.credit_price.amount).toFixed(2) }}</span>

            </div>
            <div v-else class="product-price product-price-clone" style="position: relative">

                <div v-if="product.variants.length == 0" class="" v-html="item.formatted_price" style="position: absolute; top: -5px;"></div>
                <div style="position: absolute;top:15px;">
                    <p class="" v-for="vari in product.variants">
                        <span :class="vari.is_out_of_stock?'text-red':''">{{ vari.name }}: {{ vari.special_price != null && vari.special_price.amount>0?vari.special_price.formatted:vari.price.formatted }} </span><span v-if="vari.special_price != null && vari.special_price.amount > 0" class="previous-price">{{ vari.price.formatted }}</span>
                    </p>
                </div>

            </div>

        </div>

        <div class="product-card-bottom" v-if="category == 'sell'">
            <!-- <div class="product-price fz-13" v-html="item.sell_price"></div> -->
            <div class="product-price fz-12">
                <span class='text-left'>Cash: {{ parseFloat(product.cash_price.amount).toFixed(2) }}</span><strong class='extra-bar-price'> &nbsp; | &nbsp; </strong><span class='text-right'>Credit: {{ parseFloat(product.credit_price.amount).toFixed(2) }}</span>
            </div>
            <button class="btn btn-primary only-mobile-show fz-13" @click="addToSell"
            >
                <i class="las la-rupee-sign"></i>
                <!-- {{ $trans("storefront::product_card.sell_now") }}  -->
                Sell Now
            </button>
            <button class="btn btn-primary btn-add-to-cart"
                :class="{ 'btn-loading': addingToCart }"
                @click="addToSell"
            >
                <i class="las la-rupee-sign"></i>
                <!-- {{ $trans("storefront::product_card.sell_now") }}  -->
                Sell Now
            </button>
        </div>
        <div class="product-card-bottom" v-else>
            <div v-if="product.variants.length == 0" class="product-price" v-html="item.formatted_price"></div>

            <div class="product-price" v-for="vari in product.variants">
                <span :class="vari.is_out_of_stock?'text-red':''">{{ vari.name }}: {{ vari.special_price != null && vari.special_price.amount>0?vari.special_price.formatted:vari.price.formatted }} </span><span v-if="vari.special_price != null && vari.special_price.amount > 0" class="previous-price">{{ vari.price.formatted }}</span>
            </div>

            <button v-if="hasNoOption || item.is_out_of_stock"
                class="btn btn-primary btn-add-to-cart"
                :class="{ 'btn-loading': addingToCart }"
                :disabled="item.is_out_of_stock"
                @click="addToCart"
            >
                <i class="las la-cart-arrow-down"></i>
                {{ $trans("storefront::product_card.add_to_cart") }}
            </button>

            <a v-else
                :href="productUrl"
                class="btn btn-primary btn-add-to-cart"
            >
                <i class="las la-eye"></i>
                {{ $trans("storefront::product_card.view_options") }}
            </a>
            <button v-if="hasNoOption || item.is_out_of_stock"
                class="btn btn-primary only-mobile-show fz-13"
                :class="{ 'btn-loading': addingToCart }"
                :disabled="item.is_out_of_stock"
                @click="addToCart"
            >
                <i class="las la-cart-arrow-down"></i>
                {{ $trans("storefront::product_card.add_to_cart") }}
            </button>

            <a v-else :href="productUrl"
                class="btn btn-primary only-mobile-show fz-12"
            >
                <i class="las la-eye"></i>
                {{ $trans("storefront::product_card.view_options") }}
            </a>
        </div>
    </div>
</template>

<script>
import ProductCardMixin from "../mixins/ProductCardMixin";

export default {
    mixins: [ProductCardMixin],

    props: ["product", 'category'],

    computed: {
        item() {
            return {
                ...(this.product.variant ? this.product.variant : this.product),
            };
        },
    },
};
</script>
<style scoped>
    .text-red{
        color: red;
    }
    .product-card .product-card-middle {
        padding-bottom: 30px;
    }
</style>
