<template>
    <section class="landscape-tab-products-wrap clearfix">
        <div class="container">
            <div class="tab-products-header clearfix">
                <h5 class="section-title float-left">Pre-Order</h5>
            </div>

            <div class="tab-content">
                <div class="landscape-right-tab-products">
                    <ProductCard
                        v-for="product in products"
                        :key="product.id"
                        :product="product"
                    />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import ProductCard from "../ProductCard.vue";
export default {
    name: "ProductPreOrder",
    components: { ProductCard },
    data() {
        return {
            loading: false,
            products: [],
            url: route('storefront.product_pre_order')
        };
    },
    created() {
        this.change()
    },
    methods: {

        async change() {
            this.loading = true;
            const response = await axios.get(this.url);
            this.products = response.data;
            this.loading = false;
        },
    },
}
</script>

<style scoped>

</style>
